import React from 'react';
import { NavButtonProps } from './NavButtonLang.props';
import { motion } from 'framer-motion';
import cl from './NavButtonLang.module.css'
import Lang from '../../../Images/Lang.svg'
const NavButtonLang = ({ children, ...props }: NavButtonProps) => {
    return (
        <>
            <button
                {...props}
                className={cl.btn}
            >
                <motion.img
                width='55'
                height='55'
                    className={cl.btn}
                    whileTap={{ scale: 0.9, opacity: 0.9 }}
                    src={Lang}
                    alt={'Lang'}
                />
                <p className={cl.con}>
                    {children}
                </p>
            </button>
        </>
    );
};

export default NavButtonLang;