import React from 'react';
import cl from './FirstInfoCom.module.css'
import { FirstInfoComProps } from './FirstInfoCom.props';
const FirstInfoCom = ({h1Text,h2Text}:FirstInfoComProps) => {
    return (
        <>
            <div className={cl.text}>
                <h2 className={cl.h2}>
                    {h1Text}
                </h2>
                <h3 className={cl.h3}>
                    {h2Text}
                </h3>
            </div>
        </>
    );
};

export default FirstInfoCom;