import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {t} from '../../Lang/i18n'
import { ILang } from "../../Components/Ui/Nav/Nav.props";
import Frist from '../../Components/Images/synergy25acrylicpedestal1190f.webp'
import Second from '../../Components/Images/synergy25abspedestal.png'
import Third from '../../Components/Images/synergy25outdoorabspedestal-750x376.webp'
import Four from '../../Components/Images/synergyselfcontainedpedestal.png'
import Fift from '../../Components/Images/synergyrfidretrofitpanel.png'
import Sixt from '../../Components/Images/synergycamera.png'
import Seven from '../../Components/Images/ultra15abspedestal.png'
import Eight from '../../Components/Images/ultrapostselfcontainedpedestal.png'
import Nine from '../../Components/Images//ultra18acrylicpedestal.png'
import Ten from '../../Components/Images/ultra18abspedestal.png'
import Eleven from '../../Components/Images/digitaldoormaxpedestal.png'
import Twelve from '../../Components/Images/ultralanepedestal.png'
import Thirdht from '../../Components/Images/rfidoverheadeassystem.png'
import Fourght from '../../Components/Images/synergy96mconcealedsystemt.jpg'
import Metal from '../../Components/Images/metalfoildetection.jpg'
import Ams from '../../Components/Images/ams9060controller.png'
import Eas from '../../Components/Images/integratedeastrafficcounter.png'
import Alarm from '../../Components/Images/digitalremotealarm.webp'
import Kit from '../../Components/Images/advertisingupgradekit.webp'
import Manager from '../../Components/Images/wirelessdevicemanager.png'
import Outdoor  from '../../Components/Images/outdoorenclosure.png'
import Shield from '../../Components/Images/ultrapostshield.png'
import ASA from '../../Components/Images/asa.webp'
import Apparelguard from '../../Components/Images/alertsystemsmetalguardhyperguard.webp'
import Metalguard from '../../Components/Images/essentials20macrylicrfpedestal.png'
import Essentials  from '../../Components/Images/synergy20abspedestal.png'
import Open  from '../../Components/Images/essentials20mopenframerfpedestal.webp'
import Concealed  from '../../Components/Images/synergy24concealeddoorwall.jpg'
import Floor  from '../../Components/Images/Floor.jpg'
import Hyperguard  from '../../Components/Images/alertsystemsmetalguardhyperguard (2).webp'
import SuperTag  from '../../Components/Images/supertag4-f-750x375.png'

interface InitalInterface{
    allProduct:ILang[],
    arrayId:number,
    visiblePopUp:boolean,
    changeLang:string,
}
const initialState:InitalInterface ={
    allProduct:[
        {productName:'productName1',productImg:Frist,productDescriptionText:'productDescriptionText1',productDescriptionTextLi:'productDescriptionTextLi1'},
        {productName:'productName2',productImg:Second,productDescriptionText:'productDescriptionText2',productDescriptionTextLi:'productDescriptionTextLi2'},
        {productName:'productName3',productImg:Third,productDescriptionText:'productDescriptionText3',productDescriptionTextLi:'productDescriptionTextLi3'},
        {productName:'productName4',productImg:Four,productDescriptionText:'productDescriptionText4',productDescriptionTextLi:'productDescriptionTextLi4'},
        {productName:'productName5',productImg:Fift,productDescriptionText:'productDescriptionText5',productDescriptionTextLi:'productDescriptionTextLi5'},
        {productName:'productName6',productImg:Sixt,productDescriptionText:'productDescriptionText6',productDescriptionTextLi:'productDescriptionTextLi6'},
        {productName:'productName7',productImg:Seven,productDescriptionText:'productDescriptionText7',productDescriptionTextLi:'productDescriptionTextLi7'},
        {productName:'productName8',productImg:Eight,productDescriptionText:'productDescriptionText8',productDescriptionTextLi:'productDescriptionTextLi8'},
        {productName:'productName9',productImg:Nine,productDescriptionText:'productDescriptionText9',productDescriptionTextLi:'productDescriptionTextLi9'},
        {productName:'productName10',productImg:Ten,productDescriptionText:'productDescriptionText10',productDescriptionTextLi:'productDescriptionTextLi10'},
        {productName:'productName11',productImg:Eleven,productDescriptionText:'productDescriptionText11',productDescriptionTextLi:'productDescriptionTextLi11'},
        {productName:'productName12',productImg:Twelve,productDescriptionText:'productDescriptionText12',productDescriptionTextLi:'productDescriptionTextLi12'},
        {productName:'productName13',productImg:Thirdht,productDescriptionText:'productDescriptionText13',productDescriptionTextLi:'productDescriptionTextLi13'},
        {productName:'productName14',productImg:Fourght,productDescriptionText:'productDescriptionText14',productDescriptionTextLi:'productDescriptionTextLi14'},
        {productName:'productName15',productImg:Metal,productDescriptionText:'productDescriptionText15',productDescriptionTextLi:'productDescriptionTextLi15'},
        {productName:'productName16',productImg:Ams,productDescriptionText:'productDescriptionText16',productDescriptionTextLi:'productDescriptionTextLi16'},
        {productName:'productName17',productImg:Eas,productDescriptionText:'productDescriptionText17',productDescriptionTextLi:'productDescriptionTextLi17'},
        {productName:'productName18',productImg:Alarm,productDescriptionText:'productDescriptionText18',productDescriptionTextLi:'productDescriptionTextLi18'},
        {productName:'productName19',productImg:Kit,productDescriptionText:'productDescriptionText19',productDescriptionTextLi:'productDescriptionTextLi19'},
        {productName:'productName20',productImg:Manager,productDescriptionText:'productDescriptionText20',productDescriptionTextLi:'productDescriptionTextLi20'},
        {productName:'productName21',productImg:Outdoor,productDescriptionText:'productDescriptionText21',productDescriptionTextLi:'productDescriptionTextLi21'},
        {productName:'productName22',productImg:Shield,productDescriptionText:'productDescriptionText22',productDescriptionTextLi:'productDescriptionTextLi22'},
        {productName:'productName23',productImg:ASA,productDescriptionText:'productDescriptionText23',productDescriptionTextLi:'productDescriptionTextLi23'},
        {productName:'productName24',productImg:Apparelguard,productDescriptionText:'productDescriptionText24',productDescriptionTextLi:'productDescriptionTextLi24'},
        {productName:'productName25',productImg:Essentials,productDescriptionText:'productDescriptionText25',productDescriptionTextLi:'productDescriptionTextLi25'},
        {productName:'productName26',productImg:Open,productDescriptionText:'productDescriptionText26',productDescriptionTextLi:'productDescriptionTextLi26'},
        {productName:'productName27',productImg:Metalguard,productDescriptionText:'productDescriptionText27',productDescriptionTextLi:'productDescriptionTextLi27'},
        {productName:'productName28',productImg:Concealed,productDescriptionText:'productDescriptionText28',productDescriptionTextLi:'productDescriptionTextLi28'},
        {productName:'productName29',productImg:Floor,productDescriptionText:'productDescriptionText29',productDescriptionTextLi:'productDescriptionTextLi29'},
        {productName:'productName30',productImg:Hyperguard,productDescriptionText:'productDescriptionText30',productDescriptionTextLi:'productDescriptionTextLi30'},
        {productName:'productName31',productImg:SuperTag,productDescriptionText:'productDescriptionText31',productDescriptionTextLi:'productDescriptionTextLi31'},
    ],
    visiblePopUp:false,
    arrayId:0,
    changeLang:"Ru",
}
const Product = createSlice({
    name:'product',
    initialState,
    reducers:{
        setVisibleProduct(state,action){
            state.arrayId = action.payload
            state.visiblePopUp = true
        },
        closePopUp(state){
            state.visiblePopUp = false
        },
        setChangeLang(state,action){
            state.changeLang =action.payload
        },
    },
})
export const {setVisibleProduct,closePopUp,setChangeLang} = Product.actions
export default Product.reducer