import React from 'react';
import cl from './Main.module.css'
import {t} from '../../Lang/i18n'
import { motion } from 'framer-motion';
const Main = () => {
    return (
     <>
        <motion.main
         initial={{
            y: -500
          }}
           whileInView={{
            y: 0,
            transition: {
              type: "spring",
              bounce: 0.4,
              duration: 0.8
            }}
          }
        className={cl.main}
        >
            <div className={cl.con}>
                <div className={cl.text}>
                    <h2 className={cl.h2}>
                        {t('mainH1')}
                    </h2>
                    <h3 className={cl.h3}>
                        {t('mainH2')}
                    </h3>
                </div>
            </div>
        </motion.main>
     </>
    );
};

export default Main;