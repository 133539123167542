import React, { useEffect, useState } from 'react';
import { CharacteristicsPopUpProps } from './CharacteristicsPopUp.props';
import cl from './CharacteristicsPopUp.module.css'
import { useAppSelector } from '../../../store/hooks';
import { t } from '../../../Lang/i18n'
import { motion } from 'framer-motion';

const CharacteristicsPopUp = ({ types }: CharacteristicsPopUpProps) => {
    const arrayId = useAppSelector(state => state.products.arrayId)
    const allProducts = useAppSelector(state => state.products.allProduct[arrayId])
    const [liState, setLiState] = useState<string[]>([])
    const changeLang = useAppSelector(state=>state.products.changeLang)
    useEffect(() => {
        
        const str = t(allProducts.productDescriptionTextLi)
        const features = str.split("•").map((s) => s.trim()).filter((s) => s !== "");
        setLiState(features)
      
        
    }, [arrayId,changeLang])
    
    switch (types) {
        case 'Description': return (
            <>
                <motion.div
                    animate={{ x: ['-100%', '0%'], opacity: [0, 1] }}
                    transition={{ duration: 0.8, type: 'spring' }}
                    className={cl.text}>
                    <h2 className={cl.h2}>
                        {t(allProducts.productDescriptionText)}
                    </h2>
                </motion.div>
            </>
        )
        case 'Characteristics': return (
            <>
                <motion.div
                    animate={{ x: ['100%', '0%'], opacity: [0, 1]}}
                    transition={{ duration: 0.8, type: 'spring' }}
                    className={cl.text}>
                    <ul>
                        {liState.map(i => <li className={cl.li} key={i}>{i}</li>)}
                    </ul>
                </motion.div>
            </>
        )

        default: return (<></>)
    }
};

export default CharacteristicsPopUp;