import React from 'react';
import cl from './TakeIt.module.css'
import certificat from '../Images/certificat.svg'
import { motion } from 'framer-motion';
import {t} from '../../Lang/i18n'
const TakeIt = () => {
    return (
        <>
            <motion.section>
                <div className={cl.takeit}>
                    <div className={cl.con}>
                        <h2 className={cl.h2}>
                            Take IT
                        </h2>
                        <div className={cl.info}>
                            <div className={cl.text}>
                                <h2 className={cl.text_h2}>
                                {t('TakeITH1')}
                                </h2>
                                <h3 className={cl.h3}>
                                {t('TakeITH2')}
                                </h3>
                            </div>
                            <div className={cl.imgCon} >
                                <img className={cl.img} src={certificat} alt="certificat" />
                            </div>
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    );
};

export default TakeIt;