import cl from './Nav.module.css'
import Logo from '../../Images/logoTakeIt.svg'
import NavButton from '../Buttons/NavButton/NavButton';
import { t } from '../../../Lang/i18n'
import NavButtonLang from '../Buttons/NavButtonLang/NavButtonLang';
import { NavProps } from './Nav.props';
import i18n from 'i18next';
import CallUs from '../../Images/callUS.svg'
import { motion } from 'framer-motion';
import { useAppDispatch } from '../../../store/hooks';
import { setChangeLang } from '../../../store/Reducers/ProductsReducer';
import { useEffect, useState } from 'react';
const Nav = ({ lang, setLang }: NavProps) => {
    const dispatch = useAppDispatch()
    const [width,setWidth] = useState(0)
    const changeLang = () => {
        if (lang == 'en') {
            setLang('ru')
            dispatch(setChangeLang('ru'))
            i18n.changeLanguage('ru');
            localStorage.setItem('Lang', lang);
        }
        else if (lang == 'ru') {
            setLang('en')
            dispatch(setChangeLang('en'))
            i18n.changeLanguage('en');
            localStorage.setItem('Lang', lang);
        }
    }
    const logoClick = () => {
        window.scrollTo(0, 0)
    }
   
    useEffect(()=>{
        setWidth(window.innerWidth)  
    },[width])
    return (
        <>
            <header>
                <nav className={cl.nav}>
                    <div className={cl.con}>
                        <div className={cl.img}>
                            <motion.img
                                onClick={logoClick}
                                width='257'
                                height='55'
                                whileTap={{ scale: 0.9, opacity: 0.9 }}
                                className={cl.logo} src={Logo} alt="Logo" />
                        </div>
                        <div className={cl.buttons}>
                            <a href="tel:+373 79 33 99 33" className={cl.p}>
                                {width <= 500
                                ?   <><img style={{width:'30px'}} src={CallUs} alt="" /></>
                                : <> +373 79 33 99 33</>
                                }
                               
                            </a>
                            <NavButton>
                                {t("navBtn")}
                            </NavButton>
                            <NavButtonLang
                                onClick={changeLang}
                            >
                                {t("navLang")}
                            </NavButtonLang>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default Nav;

function useSetate(): [any, any] {
    throw new Error('Function not implemented.');
}
