import React from 'react';
import cl from './Partners.module.css'
import adidas from '../Images/adidas.png'
import gues from '../Images/guess.png'
import azart from '../Images/azart.png'
import bomba from '../Images/bomba.png'
import nike from '../Images/nike.png'
import maximum from '../Images/maximum.png'
import ovico from '../Images/avico.png'
import vizage from '../Images/vizage.png'
import sportLandia from '../Images/sportLandia.svg'
import green from '../Images/green.svg'
import { motion } from 'framer-motion';
import { t } from '../../Lang/i18n'
const Partners = () => {
    return (
        <>
            <motion.section
                initial={{
                    opacity: 0
                }}
                whileInView={{
                    opacity: 1,
                    transition: {
                        type: "spring",
                        bounce: 0.4,
                        duration: 0.8
                    }
                }
                }
            >
                <div className={cl.partners}>
                    <div className={cl.con}>
                        <h2 className={cl.h2}>
                            {t('partners')}
                        </h2>
                    </div>
                    <div className={cl.info}>
                        <a className={cl.a} href="https://vizaje-nica.com/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={vizage} alt="vizage" />
                        </a>
                        <a className={cl.a} href="https://ovico.md/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={ovico} alt="ovico" />
                        </a>
                        <a className={cl.a} href="https://maximum.md/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={maximum} alt="maximum" />
                        </a>
                        <a className={cl.a} href="https://www.adidas.com/">
                            <motion.img className={cl.adidas} whileTap={{ opacity: 0.9, scale: 0.9 }} src={adidas} alt="adidas" />
                        </a>
                        <a className={cl.a} href="https://www.nike.com/">
                            <motion.img className={cl.nike} whileTap={{ opacity: 0.9, scale: 0.9 }} src={nike} alt="nike" />
                        </a>
                        <a className={cl.a} href="https://www.bomba.md/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={bomba} alt="bomba" />
                        </a>
                        <a className={cl.a} href="https://azart.md/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={azart} alt="azart" />
                        </a>
                        <a className={cl.a} href="https://guess.md/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={gues} alt="gues" />
                        </a>
                        <a className={cl.a} href="https://us.benetton.com/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={green} alt="#" />
                        </a>
                        <a className={cl.a} href="https://sportlandia.md/">
                            <motion.img className={cl.img} whileTap={{ opacity: 0.9, scale: 0.9 }} src={sportLandia} alt="sportLandia" />
                        </a>
                    </div>
                </div>
            </motion.section>
        </>
    );
};

export default Partners;