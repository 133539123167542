import React from 'react';
import cl from './Products.module.css'
import { motion } from 'framer-motion';
import { t } from '../../Lang/i18n'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ProductItem from '../Ui/ProductItem/ProductItem';
import { setVisibleProduct } from '../../store/Reducers/ProductsReducer';
const Products = () => {
    const los = useAppSelector(state=>state.products.allProduct)
    const dispatch = useAppDispatch()
    const productClick = (arrayId:number)=>{
        dispatch(setVisibleProduct(arrayId))
    }
    return (
        <>
            <motion.section
                initial={{
                    opacity:0
                }}
                whileInView={{
                    opacity:1,
                    transition: {
                        type: "spring",
                        bounce: 0.4,
                        duration: 0.8
                    }
                }
                }
            >
                <div className={cl.product}>
                    <div className={cl.con}>
                       
                        <div className={cl.products}>
                           {los.map(i=><ProductItem onClick={()=>productClick(los.findIndex(index=>index.productName == i.productName ))} key={i.productDescriptionText} productName={i.productName} productImg={i.productImg}  />)}
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    );
};

export default Products;