import React from 'react';
import cl from './Footer.module.css'
import logoTakeIT from '../Images/logoTakeIt.svg'
import linkedIn from '../Images/linkedIn.svg'
import facebook from '../Images/facebook.svg'
import insta from '../Images/insta.svg'
import map from '../Images/map-svgrepo-com.svg'
import { motion } from 'framer-motion';
const Footer = () => {
    return (
        <>
            <motion.footer
                initial={{
                    opacity: 0
                }}
                whileInView={{
                    opacity: 1,
                    transition: {
                        type: "spring",
                        bounce: 0.4,
                        duration: 0.8
                    }
                }
                }
                className={cl.footer}>
                <div className={cl.con}>
                    <div className={cl.logo_con}>
                        <div className={cl.logo}>
                            <a href="https://www.takeit.md/">
                                <motion.img
                                    whileTap={{ scale: 0.9, opacity: 0.9, }}
                                    src={logoTakeIT}
                                    alt="TakeIT"
                                    className={cl.img}
                                />
                            </a>
                            <a href="https://www.google.com/maps/place/Take+IT+SRL/@47.0144512,28.8281188,17z/data=!4m15!1m8!3m7!1s0x40c97c292f1fd959:0xd74f94b0491e1b41!2sStrada+Vasile+Alecsandri+63,+Chi%C8%99in%C4%83u+2009!3b1!8m2!3d47.0144512!4d28.8281188!16s%2Fg%2F11mv2kw8qz!3m5!1s0x40c97c0420291c09:0x8a318e1146b3bd87!8m2!3d47.0144512!4d28.8281188!16s%2Fg%2F11dd_qnhrn" className={cl.p}>
                            str. V. Alecsandri 63 mun. Chișinău, MD-2009
                            </a>
                        </div>
                    </div>

                    <div className={cl.links}>
                        <a href="https://instagram.com/take_it_srl?igshid=YmMyMTA2M2Y=">
                            <motion.img
                                className={cl.link}
                                whileTap={{ scale: 0.9, opacity: 0.9, }}
                                src={insta}
                                alt="instagram" />
                        </a>
                        <a href="https://www.facebook.com/takeit.md">
                            <motion.img
                                className={cl.link}
                                whileTap={{ scale: 0.9, opacity: 0.9, }}
                                src={facebook}
                                alt="facebook" />
                        </a>
                        <a href="https://www.linkedin.com/company/take-it-srl/mycompany/">
                            <motion.img
                                className={cl.link}
                                whileTap={{ scale: 0.9, opacity: 0.9, }}
                                src={linkedIn}
                                alt="linkedIn" />
                        </a>
                        <a href="https://www.google.com/maps/place/Take+IT+SRL/@47.0144512,28.8281188,17z/data=!4m15!1m8!3m7!1s0x40c97c292f1fd959:0xd74f94b0491e1b41!2sStrada+Vasile+Alecsandri+63,+Chi%C8%99in%C4%83u+2009!3b1!8m2!3d47.0144512!4d28.8281188!16s%2Fg%2F11mv2kw8qz!3m5!1s0x40c97c0420291c09:0x8a318e1146b3bd87!8m2!3d47.0144512!4d28.8281188!16s%2Fg%2F11dd_qnhrn">
                            <motion.img
                                className={cl.link}
                                whileTap={{ scale: 0.9, opacity: 0.9, }}
                                src={map}
                                alt="map" />
                        </a>
                    </div>

                </div>
            </motion.footer>
        </>
    );
};

export default Footer;