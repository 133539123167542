import i18n from 'i18next';
const userLanguage = navigator.language;
let lang;
const local = localStorage.getItem('Lang')
if (local) {
    lang = local
}
else if(userLanguage) {
    if (userLanguage.includes('ru')) {
        lang = 'ru'
        console.log(lang);
    }
    else if(userLanguage.includes('en')){
      lang = 'en'
      console.log(lang);
    }
}

i18n.init({
  lng: `${lang}`,
  fallbackLng: 'en',
  resources: {
    en: {
      translation: require('./Locales/en.json')
    },
    ru: {
      translation: require('./Locales/ru.json')
    }
  }
});

export const t = (key: string) => i18n.t(key);


