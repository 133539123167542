import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { PopUpProps } from './PopUp.props';
import cl from './PopUp.module.css'
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { closePopUp } from '../../../store/Reducers/ProductsReducer';
import CharacteristicsPopUp from '../CharacteristicsPopUp/CharacteristicsPopUp';
import { t } from '../../../Lang/i18n'
import ExitSvg from '../../Images/close.svg'
import CallUs from '../../Images/callUS.svg'
import { Helmet } from 'react-helmet-async';
const PopUp = ({ active }: PopUpProps) => {
    const dispatch = useAppDispatch()
    const arrayId = useAppSelector(state => state.products.arrayId)
    const allProducts = useAppSelector(state => state.products.allProduct[arrayId])
    const [activeType, setActiveType] = useState('Description')
    const [disableClick, setDisableClick] = useState(true)
    const exitClick = () => {
        dispatch(closePopUp())
    }
    const descriptionClick = () => {
        if (disableClick) {
            setDisableClick(false)
            setActiveType('Description')
            setTimeout(() => {
                setDisableClick(true)
            }, 700)
        }

    }
    const characteristicsClick = () => {
        if (disableClick) {
            setDisableClick(false)

            setActiveType('Characteristics')
            setTimeout(() => {
                setDisableClick(true)
            }, 700)
        }
    }
    return (
        <>
            <motion.div
                initial={false}
                animate={active ? {
                    opacity: 1,
                    zIndex: 3,
                    display:'flex'
                } : { display:'none' }}
                className={cl.back}
                onClick={exitClick}
            >
                {active && <Helmet>
                    <title>{t(allProducts.productName)}</title>
                    <meta
                        name="description"
                        content={t(allProducts.productDescriptionText)}
                    />

                </Helmet>}

                <motion.div
                    className={cl.con}
                    animate={active ? { y: '0vh' } : { y: '-50vh' } }
                    transition={{type:'spring',bounce:0.2}}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={cl.popup}>
                        <motion.div
                            whileTap={{ scale: 0.9, opacity: 0.9 }}
                            onClick={exitClick} className={cl.exit}>
                            <img className={cl.exitImg} src={ExitSvg} alt="ExitSvg" />
                        </motion.div >
                        <div className={cl.call}>
                            <p  className={cl.pCall}>
                               {t('PopUpCallUs')}
                            </p>
                            <img className={cl.callSvg} src={CallUs} alt="CallUs" />
                        </div>
                        <div className={cl.name}>
                            <h2 className={cl.h2}>
                                {t(allProducts.productName)}
                            </h2>
                        </div>
                        <div className={cl.info}>
                            <div className={cl.imgCon}>
                                <img className={cl.img} src={allProducts.productImg} alt={t(allProducts.productName)} />
                            </div>
                            <div className={cl.сharacteristics}>
                                <div className={cl.check}>
                                    <motion.p
                                        whileTap={{ scale: 0.9, opacity: 0.9 }}
                                        onClick={descriptionClick}
                                        className={activeType === 'Description' ? cl.pActive : cl.p}>
                                        {t('productDescription')}
                                    </motion.p>
                                    <motion.p
                                        whileTap={{ scale: 0.9, opacity: 0.9 }}
                                        onClick={characteristicsClick}
                                        className={activeType === 'Characteristics' ? cl.pActive : cl.p}>
                                        {t('productDescription2')}
                                    </motion.p>
                                    <span className={cl.line} />
                                </div>
                                <CharacteristicsPopUp types={activeType} />
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </>
    );
};

export default PopUp;