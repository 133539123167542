import React from 'react';
import cl from './FirstInfo.module.css'
import InfoBack from '../Images/infoBack.svg'
import {t} from '../../Lang/i18n'
import { motion } from 'framer-motion';
import FirstInfoCom from '../Ui/FirstInfoCom/FirstInfoCom';
const FirstInfo = () => {
    
    return (
        <>
            <motion.section
         initial={{
           opacity:0,
          }}
          whileInView={{
            opacity:1,
          }
          }
            >
                <div className={cl.first}>
                    <div className={cl.con}>
                        <div className={cl.info}>
                            <div className={cl.info_text}>
                                <h1 className={cl.h1}>
                                    {t('firstInfoH1')}
                                </h1>
                                <h2 className={cl.h2}>
                                {t('firstInfoH2')}
                                </h2>
                            </div>
                            <div className={cl.info_img}>
                                <img
                                    className={cl.img}
                                    src={InfoBack}
                                    alt="back"
                                   
                                />
                            </div>
                        </div>
                        <div className={cl.text}>
                                <FirstInfoCom h1Text={t('firstInfoH1Com1')} h2Text={t('firstInfoH2Com1')}  />
                                <FirstInfoCom h1Text={t('firstInfoH1Com2')} h2Text={t('firstInfoH2Com2')}  />
                                <FirstInfoCom h1Text={t('firstInfoH1Com3')} h2Text={t('firstInfoH2Com3')}  />
                        </div>
                    </div>
                </div>
            </motion.section>
        </>
    );
};

export default FirstInfo;