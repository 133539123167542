import React, { useState } from 'react';
import { ProductItemInterface } from './ProductItem.props';
import cl from "./ProductItem.module.css";
import { t } from '../../../Lang/i18n';
import { motion } from 'framer-motion';
const ProductItem = ({ productImg, productName,...props }: ProductItemInterface) => {

    return (
        <>
            <button
            {...props}
            >
                <motion.div
                whileTap={{scale:0.9,opacity:0.9}}
                transition={{bounce:1,duration: 0.1}}
                className={cl.con}>
                    <div>
                        <img className={cl.img} src={productImg} alt={t(productName)} />
                    </div>
                    <div className={cl.text}>
                        <h3 className={cl.h3}>
                            {t(productName)}
                        </h3>
                    </div>
                </motion.div>
            </button>
        </>
    );
};

export default ProductItem;