import './App.css';
import { Helmet } from 'react-helmet-async';
import Nav from './Components/Ui/Nav/Nav';
import Main from './Components/Main/Main';
import { useState, useEffect } from 'react';
import FirstInfo from './Components/FirstInfo/FirstInfo';
import Products from './Components/Products/Products';
import PopUp from './Components/Ui/PopUps/PopUp';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { closePopUp } from './store/Reducers/ProductsReducer';
import Partners from './Components/Partners/Partners';
import Footer from './Components/Footer/Footer';
import TakeIt from './Components/TakeIt/TakeIt';

function App() {
  const [lang, setLang] = useState('en')
  const PopUpActive = useAppSelector(state => state.products.visiblePopUp)
  const dispatch = useAppDispatch()
  const exitPopUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      dispatch(closePopUp())
    }
  }

  document.addEventListener('keydown', exitPopUp);

  

 



  return (
    <>
      <section style={PopUpActive ? { overflow: 'hidden' } : {}}>
        <Helmet>
          <link rel="icon" type="image/png" href="https://www.sensormatic.com/-/media/icons/favicon-32x32.png" />
          <title>Retail Technology Solutions - Sensormatic</title>
          <meta
            name="description"
            content="The global leader in retail systems including RFID, EAS, anti-theft tags & labels, detachers, inventory management & retail traffic solutions."
          />
          <link rel="canonical" href="/" />
          <meta name="google-site-verification" content="dwHV9KcB2Fbj0S-X98BYOPVcBrt3a-JBod8o2xqVd_4" />
          <meta name="keywords" content="Противокражка, противокражное оборудование, антикражка, противокражные антенны, sensormatic, акусто-магнитные системы, акустомагнитные системы, противокражные метки, 
            противокражные таги, противоугонные ворота, акустомагнитная технологии, деактиваторы,Detection Systems, sensormatic, Anti-Theft Protection, Anti-Theft system, Anti-Theft antenna, supertag, super tag, Synergy, rfid, Sensormatic RFID, Metal-Foil Detection, EAS, Acousto- Magnetic, AM, RF, hardtag detacher, Synergy 2.5 Acrylic Pedestal, Synergy 2.5 ABS Pedestal, Synergy 2.5m Outdoor ABS Pedestal, Synergy Self-Contained Pedestal, Synergy RFID Retrofit Panel, Sensormatic Synergy Camera, Sensormatic Pro-Max, Ultra 1.5m ABS Pedestal, Ultra•Post Self-Contained Pedestal, Ultra 1.8m Acrylic Pedestal, Ultra 1.8m ABS Pedestal System, Door-Max, Ultra•Lane Pedestal, Sensormatic RFID Overhead EAS System, Synergy 9.6m Concealed Floor System, Metal-Foil Detection, AMS9060 Controller, Integrated EAS Traffic Counter, Digital Remote Alarm, Advertising Upgrade Kit, Wireless Device Manager, Outdoor Enclosure, Ultra Post Shield, Alert Systems Apparelguard, Alert Systems Metalguard, Synergy 2.0 ABS Pedestal, Essentials 2.0m Acrylic RF Pedestal, Essentials 2.0m Open Frame RF Pedestal, Synergy 2.4m Concealed Door or Wall System, Synergy 8,6m Concealed Door or Wall System, Hyperguard System, RFID 
            система защиты от краж, защитная этикетка, акустомагнитная этикетка, съемник меток, съемник тагов, магнитный съемник для жестких бирок, металлодетектор, жесткий датчик,рфид, эрэфайди,Detection Systems, sensormatic, Anti-Theft Protection, Anti-Theft system, Anti-Theft antenna, supertag, super tag," />
        </Helmet>
        <Nav lang={lang} setLang={setLang} />
        <Main />
        <FirstInfo />
        <Products />
        <TakeIt />
        <Partners />
        <Footer />
        <PopUp active={PopUpActive} />
      </section>

    </>
  );
}

export default App;
