import React from 'react';
import { NavButtonProps } from './NavButton.props';
import { motion } from 'framer-motion';
import cl from './NavButton.module.css'
const NavButton = ({ children, ...props }: NavButtonProps) => {
    return (
        <>
            <button
                {...props}
            >
                <motion.a
                href='mailto:sales.b2b@takeit.md'
                    className={cl.btn}
                    whileTap={{ scale: 0.9, opacity: 0.9 }}
                >
                    {children}
                </motion.a>
            </button>
        </>
    );
};

export default NavButton;